/* BrokerDashboard.css - Enterprise Edition */
.broker-dashboard {
  padding: 90px 30px 40px;
  max-width: 1400px;
  margin: 0 auto;
  background-color: #f8fafc;
  min-height: 100vh;
  color: #1a202c;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

/* Loading state */
.dashboard-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 150px);
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 4px solid rgba(25, 51, 99, 0.1);
  border-radius: 50%;
  border-top: 4px solid #193363;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-spinner.small {
  width: 24px;
  height: 24px;
  border-width: 2px;
  border-top-width: 2px;
  margin-bottom: 0;
  margin-right: 8px;
}

.loading-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  color: #718096;
  font-size: 14px;
}

/* Dashboard Header */
.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.welcome-section h1 {
  font-size: 28px;
  font-weight: 700;
  color: #1a202c;
  margin: 0 0 8px;
  line-height: 1.2;
}

.dashboard-subtitle {
  font-size: 16px;
  color: #718096;
  margin: 0;
}

.header-actions {
  display: flex;
  gap: 12px;
}

.header-action-button {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #e2e8f0;
  background-color: white;
  color: #4a5568;
  cursor: pointer;
  transition: all 0.2s ease;
}

.header-action-button:hover {
  background-color: #f7fafc;
  border-color: #cbd5e0;
}

.header-action-button.primary {
  background: linear-gradient(135deg, #193363 0%, #204179 100%);
  color: white;
  border: none;
  box-shadow: 0 2px 5px rgba(25, 51, 99, 0.2);
}

.header-action-button.primary:hover {
  box-shadow: 0 4px 8px rgba(25, 51, 99, 0.3);
  transform: translateY(-1px);
}

.action-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.schedule-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%234a5568' viewBox='0 0 24 24'%3E%3Cpath d='M19 4h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V10h14v10zM9 14H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm-8 4H7v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z'/%3E%3C/svg%3E");
}

.add-client-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z'/%3E%3C/svg%3E");
}

/* Section titles */
.section-title {
  font-size: 20px;
  font-weight: 600;
  color: #2d3748;
  margin: 0 0 20px;
}

/* Performance Stats Grid */
.performance-section {
  margin-bottom: 32px;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 32px;
}

.stat-card {
  background: white;
  border-radius: 12px;
  padding: 22px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  border: 1px solid #e2e8f0;
}

.stat-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05), 0 10px 15px rgba(0, 0, 0, 0.03);
}

.stat-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
}

.stat-title {
  flex: 1;
}

.stat-title h3 {
  font-size: 15px;
  font-weight: 600;
  color: #4a5568;
  margin: 0 0 5px;
}

.stat-change {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  padding: 2px 6px;
  border-radius: 10px;
}

.stat-change.positive {
  color: #047857;
  background-color: rgba(4, 120, 87, 0.1);
}

.stat-change.negative {
  color: #dc2626;
  background-color: rgba(220, 38, 38, 0.1);
}

.change-icon {
  width: 12px;
  height: 12px;
  margin-right: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.change-icon.up {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23047857' viewBox='0 0 24 24'%3E%3Cpath d='M7 14l5-5 5 5z'/%3E%3C/svg%3E");
}

.change-icon.down {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc2626' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5z'/%3E%3C/svg%3E");
}

.stat-icon {
  width: 38px;
  height: 38px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 22px;
  background-repeat: no-repeat;
  background-position: center;
}



.stat-value {
  font-size: 28px;
  font-weight: 700;
  color: #1a202c;
  margin-bottom: 16px;
}

.stat-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.stat-period {
  font-size: 13px;
  color: #718096;
}

.stat-link {
  color: #193363;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.2s ease;
}

.stat-link:hover {
  color: #1e40af;
  text-decoration: underline;
}

/* Metrics Section */
.metrics-section {
  margin-bottom: 32px;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.metric-card {
  background: white;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid #e2e8f0;
}

.metric-icon {
  width: 44px;
  height: 44px;
  border-radius: 10px;
  margin-right: 16px;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
}


.metric-content {
  display: flex;
  flex-direction: column;
}

.metric-value {
  font-size: 20px;
  font-weight: 700;
  color: #1a202c;
  margin-bottom: 4px;
}

.metric-label {
  font-size: 14px;
  color: #718096;
}

/* Dashboard Content Layout */
.dashboard-content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 24px;
}

.dashboard-column {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* Card Components */
.chart-card,
.referral-card,
.broker-profile-card,
.activities-card,
.clients-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  border: 1px solid #e2e8f0;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #edf2f7;
}

.card-header h2 {
  font-size: 18px;
  font-weight: 600;
  color: #2d3748;
  margin: 0;
}

.card-actions {
  display: flex;
  gap: 10px;
  align-items: center;
}

.card-subtitle {
  font-size: 13px;
  color: #718096;
  margin-top: 4px;
}

.view-all-link {
  color: #193363;
  font-size: 13px;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.2s ease;
}

.view-all-link:hover {
  color: #1e40af;
  text-decoration: underline;
}

/* Chart Card */
.chart-container {
  padding: 20px;
}

.period-select {
  padding: 6px 10px;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
  background-color: white;
  font-size: 13px;
  font-family: inherit;
  color: #4a5568;
  cursor: pointer;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.period-select:focus {
  outline: none;
  border-color: #193363;
  box-shadow: 0 0 0 1px rgba(25, 51, 99, 0.1);
}

/* Chart visualization (simplified version) */
.revenue-chart {
  height: 200px;
  display: flex;
  align-items: flex-end;
  gap: 16px;
  padding-top: 20px;
}

.chart-bar-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.chart-bar {
  width: 100%;
  max-width: 40px;
  background: linear-gradient(180deg, #204179 0%, #1091cc 100%);
  border-radius: 4px 4px 0 0;
  margin-bottom: 8px;
  position: relative;
  min-height: 4px;
}

.chart-value {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  font-weight: 500;
  color: #4a5568;
  white-space: nowrap;
}

.chart-label {
  font-size: 13px;
  color: #718096;
}

/* Referral Card */
.referral-link-container {
  padding: 20px;
}

.referral-stats {
  display: flex;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #f8fafc;
  overflow: hidden;
}

.referral-stat {
  flex: 1;
  padding: 16px;
  text-align: center;
  border-right: 1px solid #edf2f7;
}

.referral-stat:last-child {
  border-right: none;
}

.referral-stat-value {
  font-size: 20px;
  font-weight: 700;
  color: #193363;
  margin-bottom: 4px;
}

.referral-stat-label {
  font-size: 13px;
  color: #718096;
}

.referral-link-input-container {
  display: flex;
  margin-top: 16px;
}

.referral-link-input {
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #e2e8f0;
  border-right: none;
  border-radius: 8px 0 0 8px;
  font-size: 14px;
  color: #4a5568;
  background-color: #f8fafc;
}

.referral-link-input:focus {
  outline: none;
  border-color: #193363;
}

.copy-link-button {
  padding: 12px 20px;
  background: linear-gradient(135deg, #193363 0%, #204179 100%);
  color: white;
  border: none;
  border-radius: 0 8px 8px 0;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
}

.copy-link-button:hover {
  background: linear-gradient(135deg, #204179 0%, #19315d 100%);
}

.copy-icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='white' viewBox='0 0 24 24'%3E%3Cpath d='M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* Profile Card */
.broker-profile-card {
  background-color: white;
  height: 100%;
}

.broker-profile-header {
  display: flex;
  padding: 24px;
  background: linear-gradient(135deg, #f8fafc 0%, #edf2f7 100%);
  border-bottom: 1px solid #edf2f7;
  position: relative;
}

.broker-profile-avatar {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  background: linear-gradient(135deg, #193363 0%, #204179 100%);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 24px;
  margin-right: 16px;
  box-shadow: 0 2px 6px rgba(25, 51, 99, 0.2);
}

.broker-profile-info {
  flex: 1;
}

.broker-profile-name {
  font-size: 18px;
  font-weight: 600;
  color: #1a202c;
  margin: 0 0 4px;
}

.broker-profile-title {
  font-size: 14px;
  color: #4a5568;
  margin: 0 0 8px;
}

.broker-profile-id {
  font-size: 13px;
  color: #718096;
  margin: 0 0 8px;
}

.broker-profile-status {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #38a169;
}

.status-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 6px;
}

.status-indicator.active {
  background-color: #38a169;
}

.broker-profile-edit-button {
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4a5568;
  border: 1px solid #e2e8f0;
  transition: all 0.2s ease;
}

.broker-profile-edit-button:hover {
  background-color: #f7fafc;
  border-color: #cbd5e0;
}

.edit-icon {
  width: 16px;
  height: 16px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%234a5568' viewBox='0 0 24 24'%3E%3Cpath d='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.broker-profile-details {
  padding: 20px;
}

.broker-profile-detail {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.broker-profile-detail:last-child {
  margin-bottom: 0;
}

.detail-icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.7;
}

.email-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%234a5568' viewBox='0 0 24 24'%3E%3Cpath d='M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z'/%3E%3C/svg%3E");
}

.phone-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%234a5568' viewBox='0 0 24 24'%3E%3Cpath d='M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z'/%3E%3C/svg%3E");
}

.calendar-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%234a5568' viewBox='0 0 24 24'%3E%3Cpath d='M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z'/%3E%3C/svg%3E");
}

.location-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%234a5568' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z'/%3E%3C/svg%3E");
}

.detail-content {
  flex: 1;
}

.detail-label {
  font-size: 12px;
  color: #718096;
  margin-bottom: 4px;
}

.detail-value {
  font-size: 14px;
  color: #2d3748;
  font-weight: 500;
}

/* Activities Card */
.activities-card {
  height: 100%;
}

.activities-list {
  padding: 10px 0;
}

.activity-item {
  display: flex;
  padding: 14px 20px;
  border-left: 3px solid transparent;
  transition: background-color 0.2s ease;
}

.activity-item:hover {
  background-color: #f8fafc;
}

.activity-item.completed {
  border-left-color: #10b981;
}

.activity-item.pending {
  border-left-color: #f59e0b;
}

.activity-icon {
  width: 36px;
  height: 36px;
  border-radius: 8px;
  margin-right: 14px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
  flex-shrink: 0;
}

.lead-activity-icon {
  background-color: rgba(249, 115, 22, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23f97316' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 4c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm0 14c-2.03 0-4.43-.82-6.14-2.88C7.55 15.8 9.68 15 12 15s4.45.8 6.14 2.12C16.43 19.18 14.03 20 12 20z'/%3E%3C/svg%3E");
}

.partner-activity-icon {
  background-color: rgba(79, 70, 229, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%234f46e5' viewBox='0 0 24 24'%3E%3Cpath d='M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14l-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z'/%3E%3C/svg%3E");
}

.deal-activity-icon {
  background-color: rgba(16, 185, 129, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2310b981' viewBox='0 0 24 24'%3E%3Cpath d='M21 18v1c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2V5c0-1.1.89-2 2-2h14c1.1 0 2 .9 2 2v1h-9c-1.11 0-2 .9-2 2v8c0 1.1.89 2 2 2h9zm-9-2h10V8H12v8zm4-2.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z'/%3E%3C/svg%3E");
}

.finance-activity-icon {
  background-color: rgba(6, 182, 212, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2306b6d4' viewBox='0 0 24 24'%3E%3Cpath d='M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z'/%3E%3C/svg%3E");
}

.client-activity-icon {
  background-color: rgba(236, 72, 153, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23ec4899' viewBox='0 0 24 24'%3E%3Cpath d='M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z'/%3E%3C/svg%3E");
}

.activity-content {
  flex: 1;
}

.activity-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5px;
}

.activity-title {
  font-size: 14px;
  font-weight: 500;
  color: #2d3748;
}

.activity-time {
  font-size: 12px;
  color: #a0aec0;
  white-space: nowrap;
}

.activity-entity {
  font-size: 13px;
  color: #4a5568;
}

/* Clients Card */
.clients-card {
  height: 100%;
}

.clients-list {
  padding: 10px 20px;
}

.client-item {
  display: flex;
  align-items: center;
  padding: 14px;
  border-radius: 8px;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
  background-color: #f8fafc;
}

.client-item:hover {
  background-color: #edf2f7;
}

.client-avatar {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: linear-gradient(135deg, #4f46e5 0%, #7c3aed 100%);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  margin-right: 14px;
  flex-shrink: 0;
}

.client-content {
  flex: 1;
  min-width: 0; /* Ensures text truncation works in flex child */
}

.client-name {
  font-size: 15px;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.client-details, .client-contact {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #718096;
  margin-bottom: 2px;
}

.client-type, .client-stores, .client-email, .client-phone {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.client-separator {
  margin: 0 6px;
  color: #cbd5e0;
}

.client-status {
  padding: 4px 10px;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
  flex-shrink: 0;
}

.client-status.active {
  background-color: rgba(16, 185, 129, 0.1);
  color: #10b981;
}

.client-status.pending {
  background-color: rgba(245, 158, 11, 0.1);
  color: #f59e0b;
}

.see-all-link {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  color: #193363;
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  border-top: 1px solid #edf2f7;
  margin-top: 10px;
  transition: color 0.2s ease;
}

.see-all-link:hover {
  color: #1e40af;
}

.arrow-right-icon {
  width: 16px;
  height: 16px;
  margin-left: 6px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23193363' viewBox='0 0 24 24'%3E%3Cpath d='M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z'/%3E%3C/svg%3E");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* Empty state styling */
.empty-state {
  padding: 30px 20px;
  text-align: center;
}

.empty-icon {
  width: 60px;
  height: 60px;
  margin: 0 auto 16px;
  opacity: 0.4;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.clients-empty-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23a0aec0' viewBox='0 0 24 24'%3E%3Cpath d='M16.5 12c1.38 0 2.49-1.12 2.49-2.5S17.88 7 16.5 7C15.12 7 14 8.12 14 9.5s1.12 2.5 2.5 2.5zM9 11c1.66 0 2.99-1.34 2.99-3S10.66 5 9 5C7.34 5 6 6.34 6 8s1.34 3 3 3zm7.5 3c-1.83 0-5.5.92-5.5 2.75V19h11v-2.25c0-1.83-3.67-2.75-5.5-2.75zM9 13c-2.33 0-7 1.17-7 3.5V19h7v-2.25c0-.85.33-2.34 2.37-3.47C10.5 13.1 9.66 13 9 13z'/%3E%3C/svg%3E");
}

.empty-state h3 {
  font-size: 16px;
  font-weight: 600;
  color: #4a5568;
  margin: 0 0 8px;
}

.empty-state p {
  font-size: 14px;
  color: #718096;
  margin: 0 0 20px;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.primary-button {
  padding: 10px 16px;
  background: linear-gradient(135deg, #193363 0%, #204179 100%);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(25, 51, 99, 0.2);
}

.primary-button:hover {
  box-shadow: 0 4px 6px rgba(25, 51, 99, 0.3);
  transform: translateY(-1px);
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .stats-grid, .metrics-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .dashboard-content {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 768px) {
  .broker-dashboard {
    padding: 80px 16px 30px;
  }
  
  .dashboard-header {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
  }
  
  .welcome-section h1 {
    font-size: 24px;
  }
  
  .header-actions {
    width: 100%;
  }
  
  .header-action-button {
    flex: 1;
    justify-content: center;
  }
  
  .stats-grid, .metrics-grid {
    grid-template-columns: 1fr;
  }
  
  .stat-value, .metric-value {
    font-size: 18px;
  }
  
  .revenue-chart {
    height: 150px;
  }
  
  .referral-stats {
    flex-direction: column;
  }
  
  .referral-stat {
    border-right: none;
    border-bottom: 1px solid #edf2f7;
  }
  
  .referral-link-input-container {
    flex-direction: column;
  }
  
  .referral-link-input {
    border-radius: 8px 8px 0 0;
    border-right: 1px solid #e2e8f0;
  }
  
  .copy-link-button {
    border-radius: 0 0 8px 8px;
    padding: 10px;
  }
}

/* Print styles */
@media print {
  .broker-dashboard {
    padding: 0;
    background-color: white;
  }
  
  .broker-navbar {
    display: none;
  }
  
  .dashboard-header {
    margin-bottom: 20px;
  }
  
  .header-actions {
    display: none;
  }
  
  .stat-card, .metric-card, .chart-card, .referral-card, .broker-profile-card, .activities-card, .clients-card {
    box-shadow: none;
    border: 1px solid #e2e8f0;
    break-inside: avoid;
  }
  
  .dashboard-content {
    grid-template-columns: 1fr;
  }
  
  .see-all-link, .stat-link, .view-all-link {
    display: none;
  }
}