/* PricingPlan.css */
.pricing-container {
    margin: 40px 0;
    font-family: inherit;
  }
  
  .pricing-heading {
    text-align: center;
    margin-bottom: 30px;
    color: #193363;
    font-size: 28px;
    font-weight: 600;
  }
  
  .pricing-plans {
    display: flex;
    justify-content: center;
    gap: 16px;
    margin: 0 auto;
    max-width: 1350px;
    flex-wrap: nowrap;
  }
  
  .pricing-plan {
    flex: 1;
    min-width: 0; /* Allow cards to shrink if needed */
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease;
    position: relative;
    cursor: pointer;
  }
  
  .pricing-plan:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.15);
  }
  
  .pricing-plan.selected {
    transform: translateY(-8px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
  }
 

  
  /* Add a checkmark icon to selected plan */
  .pricing-plan.selected::before {
    content: "✓";
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    background-color: #ffffff;
    color: rgb(1, 76, 252);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    z-index: 2;
  }
  
  .plan-header {
    padding: 25px 20px;
    text-align: center;
    color: white;
    position: relative;
  }
  
  .plan-header h3 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
  }
  
  .price {
    font-size: 32px;
    font-weight: 700;
    margin-top: 15px;
  }
  
  /* Plan specific colors */
  .free {
    background: linear-gradient(120deg, #2884db 0%, #0359b4 100%);
  }
  
  .pro {
    background: linear-gradient(120deg, #fc9749 0%, #d84d07 100%);
  }
  
  .business {
    background: linear-gradient(120deg, #1e3c72 0%, #2a5298 100%);
  }
  
  .enterprise {
    background: linear-gradient(120deg, #2d3748 0%, #1a202c 100%);
  }
  
  .popular-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #f8a137;
    color: #fff;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 600;
  }
  
  .plan-features {
    padding: 20px;
    height: 420px;
    background-color: white;
  }
  
  .plan-features ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
  }
  
  .plan-features li {
    margin-bottom: 15px;
    font-size: 14px;
    line-height: 1.5;
    color: #4a5568;
    position: relative;
    padding-left: 20px;
  }
  
  .plan-features li:before {
    content: "•";
    position: absolute;
    left: 0;
    color: #3182ce;
    font-weight: bold;
  }
  
  .plan-features li strong {
    font-weight: 600;
    color: #2d3748;
    display: inline-block;
    margin-right: 5px;
  }

  
  /* Update form buttons to be more prominent */
  .form-buttons {
    display: flex;
    gap: 16px;
    margin-top: 30px;
    justify-content: center;
  }
  
  .auth-button {
    min-width: 150px;
  }
  
  /* Make auth-card wider for the pricing plans */
  .auth-card-wide {
    max-width: 1280px !important;
    width: 95% !important;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1100px) {
    .pricing-plans {
      flex-wrap: wrap;
    }
    
    .pricing-plan {
      flex-basis: 47%;
      max-width: 47%;
    }
  }
  
  @media (max-width: 768px) {
    .pricing-plan {
      flex-basis: 100%;
      max-width: 100%;
    }
    
    .plan-features {
      height: auto;
      max-height: 300px;
    }
  }