/* BrokerNavBar.css - Enterprise Edition */
.broker-navbar {
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 70px;
  border-bottom: 1px solid rgba(226, 232, 240, 0.8);
}

.broker-navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 24px;
  height: 100%;
}

/* Logo styling */
.navbar-logo {
  display: flex;
  align-items: center;
  height: 100%;
}

.navbar-logo img {
  height: 30px;
  margin-right: 6px;
  transition: opacity 0.2s ease;
}

.navbar-logo:hover img {
  opacity: 0.9;
}

/* Menu styling */
.navbar-menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  height: 100%;
}

.navbar-menu li {
  height: 100%;
  position: relative;
}

.navbar-menu li a {
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: #4a5568;
  text-decoration: none;
  font-weight: 500;
  height: 100%;
  transition: color 0.2s ease, background-color 0.2s ease;
  font-size: 15px;
}

.navbar-menu li a:hover {
  color: #193363;
  background-color: rgba(25, 51, 99, 0.03);
}

.navbar-menu li.active-nav-link a {
  color: #193363;
  font-weight: 600;
  box-shadow: inset 0 -3px 0 #193363;
  background-color: rgba(25, 51, 99, 0.04);
}

/* Enhance the navigation icons */
.nav-icon {
  margin-right: 8px;
  width: 18px;
  height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.8;
  transition: opacity 0.2s ease;
}

.navbar-menu li a:hover .nav-icon {
  opacity: 1;
}

.dashboard-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23193363' viewBox='0 0 24 24'%3E%3Cpath d='M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z'/%3E%3C/svg%3E");
}

.partners-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23193363' viewBox='0 0 24 24'%3E%3Cpath d='M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z'/%3E%3C/svg%3E");
}

.clients-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23193363' viewBox='0 0 24 24'%3E%3Cpath d='M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z'/%3E%3C/svg%3E");
}

.finances-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23193363' viewBox='0 0 24 24'%3E%3Cpath d='M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z'/%3E%3C/svg%3E");
}

.notifications-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23193363' viewBox='0 0 24 24'%3E%3Cpath d='M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z'/%3E%3C/svg%3E");
}

.deals-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23193363' viewBox='0 0 24 24'%3E%3Cpath d='M20 6h-4V4c0-1.11-.89-2-2-2h-4c-1.11 0-2 .89-2 2v2H4c-1.11 0-1.99.89-1.99 2L2 19c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V8c0-1.11-.89-2-2-2zm-6 0h-4V4h4v2zm-3 8H9v2h2v-2zm0-4H9v2h2v-2zm4 4h-2v2h2v-2zm0-4h-2v2h2v-2z'/%3E%3C/svg%3E");
}

/* Notification badge enhanced look */
.notification-badge {
  background: linear-gradient(135deg, #1091cc 0%, #1e40af 100%);
  color: white;
  border-radius: 10px;
  min-width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: bold;
  margin-left: 8px;
  padding: 0 5px;
  box-shadow: 0 2px 4px rgba(16, 145, 204, 0.3);
}

/* User section styling */
.user-section {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.user-profile-dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 14px;
  border-radius: 8px;
  position: relative;
  transition: background-color 0.2s ease;
}

.user-profile-dropdown:hover {
  background-color: rgba(226, 232, 240, 0.5);
}

.user-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: linear-gradient(135deg, #193363 0%, #204179 100%);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  margin-right: 10px;
  box-shadow: 0 2px 5px rgba(25, 51, 99, 0.2);
  font-size: 16px;
}

.user-info {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.broker-user-name {
  font-weight: 600;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2d3748;
  font-size: 14px;
}

.broker-user-role {
  font-size: 12px;
  color: #718096;
}

/* Dropdown arrow styling */
.dropdown-arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin-left: 4px;
  transition: transform 0.2s ease;
}

.dropdown-arrow.down {
  border-top: 5px solid #718096;
  border-bottom: 0;
}

.dropdown-arrow.up {
  border-bottom: 5px solid #718096;
  border-top: 0;
  transform: translateY(-2px);
}

/* Enhanced dropdown menu */
.user-dropdown-menu {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  background: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  min-width: 240px;
  z-index: 1001;
  overflow: hidden;
  animation: dropdownFade 0.2s ease;
}

@keyframes dropdownFade {
  from { opacity: 0; transform: translateY(-8px); }
  to { opacity: 1; transform: translateY(0); }
}

.dropdown-header {
  padding: 16px;
  border-bottom: 1px solid #edf2f7;
  background: linear-gradient(180deg, #f8fafc 0%, #f1f5f9 100%);
}

.dropdown-user-info {
  display: flex;
  flex-direction: column;
}

.dropdown-broker-user-name {
  font-weight: 600;
  font-size: 14px;
  color: #1a202c;
  margin-bottom: 2px;
}

.dropdown-user-email {
  font-size: 13px;
  color: #718096;
}

.user-dropdown-menu a, 
.user-dropdown-menu button {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  color: #4a5568;
  text-decoration: none;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.user-dropdown-menu a:hover, 
.user-dropdown-menu button:hover {
  background-color: #f8fafc;
  color: #193363;
}

.user-dropdown-menu i {
  margin-right: 10px;
  width: 18px;
  height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0.7;
}

.dropdown-divider {
  height: 1px;
  background-color: #edf2f7;
  margin: 6px 0;
}

/* Dropdown icons */
.profile-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23193363' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z'/%3E%3C/svg%3E");
}

.settings-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23193363' viewBox='0 0 24 24'%3E%3Cpath d='M19.14 12.94c.04-.3.06-.61.06-.94 0-.32-.02-.64-.07-.94l2.03-1.58a.49.49 0 00.12-.61l-1.92-3.32a.488.488 0 00-.59-.22l-2.39.96c-.5-.38-1.03-.7-1.62-.94l-.36-2.54a.484.484 0 00-.48-.41h-3.84c-.24 0-.43.17-.47.41l-.36 2.54c-.59.24-1.13.57-1.62.94l-2.39-.96c-.22-.08-.47 0-.59.22L2.74 8.87c-.12.21-.08.47.12.61l2.03 1.58c-.05.3-.09.63-.09.94s.02.64.07.94l-2.03 1.58a.49.49 0 00-.12.61l1.92 3.32c.12.22.37.29.59.22l2.39-.96c.5.38 1.03.7 1.62.94l.36 2.54c.05.24.24.41.48.41h3.84c.24 0 .44-.17.47-.41l.36-2.54c.59-.24 1.13-.56 1.62-.94l2.39.96c.22.08.47 0 .59-.22l1.92-3.32c.12-.22.07-.47-.12-.61l-2.01-1.58zM12 15.6c-1.98 0-3.6-1.62-3.6-3.6s1.62-3.6 3.6-3.6 3.6 1.62 3.6 3.6-1.62 3.6-3.6 3.6z'/%3E%3C/svg%3E");
}

.help-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23193363' viewBox='0 0 24 24'%3E%3Cpath d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z'/%3E%3C/svg%3E");
}

.logout-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23193363' viewBox='0 0 24 24'%3E%3Cpath d='M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z'/%3E%3C/svg%3E");
}

.broker-logout-button {
  color: #e53e3e !important;
}

.broker-logout-button:hover {
  background-color: rgba(229, 62, 62, 0.05) !important;
}

.broker-logout-button .logout-icon {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23e53e3e' viewBox='0 0 24 24'%3E%3Cpath d='M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z'/%3E%3C/svg%3E") !important;
}

/* Mobile menu button */
.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #4a5568;
  padding: 8px;
  margin-right: 8px;
  border-radius: 4px;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.mobile-menu-button:hover {
  background-color: #f8fafc;
  color: #193363;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .mobile-menu-button {
    display: block;
  }

  .navbar-menu {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    background-color: white;
    flex-direction: column;
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, box-shadow 0.3s ease;
    box-shadow: none;
    z-index: 999;
  }

  .navbar-menu.open {
    max-height: 500px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }

  .navbar-menu li {
    height: auto;
    border-bottom: 1px solid #f1f5f9;
  }

  .navbar-menu li:last-child {
    border-bottom: none;
  }

  .navbar-menu li a {
    padding: 16px 24px;
    justify-content: flex-start;
  }

  .navbar-menu li.active-nav-link a {
    box-shadow: inset 4px 0 0 #193363;
    background-color: rgba(25, 51, 99, 0.04);
  }
  
  .broker-user-name, .broker-user-role {
    display: none;
  }
  
  .user-profile-dropdown {
    padding: 4px;
  }
  
  .dropdown-arrow {
    display: none;
  }
}

@media (max-width: 480px) {
  .broker-navbar-container {
    padding: 0 16px;
  }
  
  .navbar-logo img {
    height: 24px;
  }
  
  .user-avatar {
    width: 32px;
    height: 32px;
    margin-right: 0;
  }
  
  .user-dropdown-menu {
    right: -16px;
    min-width: 280px;
  }
}