body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
  background-color: #e3e9ee !important;
}

@media (max-width: 1200px) {
  .dashboard-container {
    max-width: 1380px;
    margin: 0 auto;
    padding: 20px 40px 0 40px;
 }
}

@media (min-width: 1201px) {
  .dashboard-container {
    max-width: 1380px;
    margin: 0 auto;
    padding: 20px;
    padding-left: 325px;
    padding-right: 40px;
 }
}

.dashboard-title {
  font-size: 28px;
  color: #ffffff;
  margin-bottom: 30px;
  text-align: center;
}

.brand-welcome {
  font-size: 22px;
  color: #ffffff;
  margin-bottom: 20px;
}

.inventory-summary, .inventory-analytics {
  position: relative;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 31, 71, 0.418);
  transition: box-shadow 0.3s ease;
}

.inventory-summary:hover, .inventory-analytics:hover {
  box-shadow: 0 6px 8px rgba(0,0,0,0.15);
}

.inventory-summary .h3-dashboard {
  font-size: 20px;
  color: #131d27;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.inventory-summary .h3-dashboard, .inventory-analytics .h3-dashboard {
  font-size: 20px;
  color: #131d27;
  margin-bottom: 10px;
}

hr {
  width: 100%;
  border: 1px solid #131d27;
  margin-top: 10px;
  margin-bottom: 30px;
}

.summary-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
}

.summary-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.summary-label {
  font-size: 14px;
  color: #7f8c8d;
  margin-bottom: 5px;
}

.summary-value {
  font-size: 24px;
  font-weight: bold;
  color: #2c3e50;
}

.inventory-analytics {
  height: 400px;
}

.analytics-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.custom-tooltip {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #bdc3c7;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.custom-tooltip .label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #2c3e50;
}

.custom-tooltip .info {
  margin: 3px 0;
}

.custom-tooltip .info.added {
  color: #008a47;
}

.custom-tooltip .info.sale {
  color: #046ed1;
}

.more-analytics-btn, .back-btn {
  color: #0093e1 !important;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
  text-decoration: underline;
}

.more-analytics-btn:hover, .back-btn:hover {
  color: #017dc0 !important;
}

.expanded-analytics-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1010;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
 from { opacity: 0; }
 to { opacity: 1; }
}

.expanded-analytics-overlay.closing {
  animation: fadeOut 0.3s ease;
}

@keyframes fadeOut {
 from { opacity: 1; }
 to { opacity: 0; }
}

.expanded-analytics-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  height: 90%;
  overflow: auto;
}

.expanded-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expanded-header-actions {
  display: flex;
  align-items: center;
  gap: 15px;
}

.chart-selector-wrapper {
  display: flex;
  align-items: center;
}

.expanded-chart {
  height: 300px;
  margin: 20px 0;
}

.filter-controls {
  background-color: #f5f7fa;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.filter-header h4 {
  margin: 0;
  color: #2c3e50;
  font-size: 18px;
}

.reset-filters-btn {
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  border: none;
  transition: all 0.2s ease;
  background-color: #e0e0e0;
  color: #333;
}

.reset-filters-btn:hover {
  background-color: #c0c0c0;
}

.reset-filters-btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.filter-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
}

.filter-group {
  display: flex;
  flex-direction: column;
}

.filter-group label {
  font-size: 14px;
  margin-bottom: 5px;
  color: #555;
}

.filter-select {
  padding: 8px 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
}

.filtering-indicator {
  display: flex;
  align-items: center;
  margin-top: 15px;
  font-size: 14px;
  color: #555;
}

.loading-spinner {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid #ddd;
  border-top: 2px solid #0093e1;
  border-radius: 50%;
  margin-right: 8px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
 0% { transform: rotate(0deg); }
 100% { transform: rotate(360deg); }
}

.active-filters {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #eee;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.filter-tag {
  background-color: #e1f5fe;
  color: #0277bd;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 5px;
}

.analytics-stats {
  margin-top: 30px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-bottom: 20px;
}

.stat-card {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 6px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.stat-title {
  font-size: 14px;
  color: #7f8c8d;
  margin-bottom: 5px;
}

.stat-value {
  font-size: 24px;
  font-weight: bold;
  color: #2c3e50;
}

.trend-analysis {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 6px;
  margin-top: 20px;
}

.trend-analysis h4 {
  font-size: 16px;
  color: #2c3e50;
  margin: 0 0 10px 0;
}

.trend-analysis p {
  font-size: 14px;
  color: #555;
  line-height: 1.5;
  margin: 0;
}

.dax-hover-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, #023777, #013c84);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 6px;
}

.dax-hover-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: white;
}

.dax-hover-text {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1.5rem;
  font-weight: 600;
}

/* Show overlay when section is hovered and Dax is enabled */
.inventory-summary:hover .dax-hover-overlay,
.inventory-analytics:hover .dax-hover-overlay {
  display: block;
  opacity: 1;
}

/* Chart Type Selector Styles */
.chart-type-selector {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.chart-type-selector label {
  font-size: 14px;
  color: #555;
  margin-right: 10px;
}

.chart-type-select {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: all 0.2s ease;
}

.chart-type-select:hover {
  border-color: #bbb;
}

.chart-type-select:focus {
  outline: none;
  border-color: #0093e1;
}

/* Custom styles for recharts */
.recharts-rectangle.recharts-bar-rectangle {
  transition: opacity 0.3s ease;
}

.recharts-rectangle.recharts-bar-rectangle:hover {
  opacity: 0.6;
}

/* Make sure the background highlight is semi-transparent */
.recharts-tooltip-cursor {
  fill: rgba(200, 200, 200, 0.3) !important;
}

/* Ensure grid lines are visible on hover */
.recharts-cartesian-grid-horizontal line,
.recharts-cartesian-grid-vertical line {
  z-index: 0;
  stroke: #e0e0e0;
  stroke-width: 1;
}

.recharts-bar {
  z-index: 1;
}

@media (max-width: 768px) {
  .filter-grid {
    grid-template-columns: 1fr;
 }

  .filter-header {
    flex-direction: column;
    align-items: flex-start;
 }

  .filter-actions {
    margin-top: 10px;
 }

  .summary-grid {
    grid-template-columns: 1fr 1fr;
 }

  .analytics-header {
    flex-direction: column;
    align-items: flex-start;
 }
  
  .analytics-controls {
    margin-top: 10px;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .more-analytics-btn {
    margin-top: 10px;
 }

  .stats-grid {
    grid-template-columns: 1fr;
 }
  
  .expanded-header-actions {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .chart-selector-wrapper {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .chart-type-select {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .summary-grid {
    grid-template-columns: 1fr;
 }

  .expanded-analytics-content {
    width: 95%;
    padding: 15px;
 }
  
  /* Chart selector is only in expanded view */
}

.date-range-filter {
  background-color: #f7f9fc;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);
}

.date-filter-header {
  margin-bottom: 0.5rem;
}

.date-filter-header h4 {
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  color: #333;
}

.date-inputs {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
}

.date-input-group {
  flex: 1;
  min-width: 200px;
}

.date-input-group label {
  display: block;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  color: #555;
}

.date-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.875rem;
}

.apply-date-filter-btn {
  padding: 0.5rem 1rem;
  background: linear-gradient(120deg, #1655ae 0%, #2060ae 100%);  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
  margin-top: 1.5rem;
  height: 38px;
}

.apply-date-filter-btn:hover {
  background: linear-gradient(120deg, #19498d 0%, #134078 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);}

.apply-date-filter-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.active-date-filter {
  margin-top: 0.75rem;
}

.date-filter-tag {
  display: inline-block;
  background-color: #eff2f9;
  color: #4a5568;
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.875rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #e2e8f0;
}

/* Compact date filter for non-expanded view */
.date-filter-compact {
  margin-right: 1rem;
}

.date-filter-compact .date-range-filter {
  background-color: transparent;
  padding: 0;
  box-shadow: none;
  margin-bottom: 0;
}

.date-filter-compact .date-filter-header {
  display: none;
}

.date-filter-compact .date-inputs {
  gap: 0.5rem;
}

.date-filter-compact .date-input-group {
  min-width: 120px;
}

.date-filter-compact .date-input {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
}

.date-filter-compact .apply-date-filter-btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  height: 28px;
  margin-top: 1.2rem;
}

.date-filter-compact .active-date-filter {
  display: none;
}

/* Analytics header with date filter */
.analytics-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.analytics-controls {
  display: flex;
  align-items: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .date-inputs {
    flex-direction: column;
    gap: 0.5rem;
 }
  
  .date-input-group {
    width: 100%;
 }
  
  .apply-date-filter-btn {
    width: 100%;
    margin-top: 0.5rem;
 }
  
  .date-filter-compact {
    display: none; /* Hide compact filter on mobile */
 }
}


.pattern-analysis-btn {
  padding: 8px 16px;
  background: linear-gradient(120deg, #1655ae 0%, #2060ae 100%);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background 0.2s, transform 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pattern-analysis-btn:hover {
  background: linear-gradient(120deg, #19498d 0%, #134078 100%);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.pattern-analysis-btn:active {
  transform: scale(0.98);
}

/* Analytics chart container to ensure proper spacing */
.analytics-chart-container {
  position: relative;
  width: 100%;
  height: 300px;
}

/* Updated expanded-header-actions to accommodate the pattern analysis button */
.expanded-header-actions {
  display: flex;
  align-items: center;
  gap: 15px;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .expanded-header-actions {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  
  .pattern-analysis-btn {
    width: 100%;
    margin-bottom: 10px;
  }
}

/* Add styles for the Pattern Analysis modal */
.pattern-analysis-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1020;
  animation: fadeIn 0.3s ease;
}