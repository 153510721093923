/* PatternAnalysis.css */
.pattern-analysis-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1010;
  animation: fadeIn 0.3s ease;
}

.pattern-analysis-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  height: 90%;
  max-width: 1200px;
  overflow: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.pattern-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pattern-header-controls {
  display: flex;
  align-items: center;
  gap: 15px;
}

.day-select-wrapper, .chart-type-wrapper {
  position: relative;
}

.day-select, .chart-type-select {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  background-color: #f9f9f9;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 120px;
}

.day-select:hover, .chart-type-select:hover {
  border-color: #bbb;
}

.day-select:focus, .chart-type-select:focus {
  outline: none;
  border-color: #0093e1;
  box-shadow: 0 0 0 2px rgba(0, 147, 225, 0.1);
}



.pattern-chart {
  height: 400px;
  margin: 20px 0;
  position: relative;
}

.calculating-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 10px;
  color: #666;
}

.loading-spinner {
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 3px solid #eee;
  border-top: 3px solid #0093e1;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.no-data-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #666;
  font-style: italic;
}

.pattern-tooltip {
  background-color: rgba(255, 255, 255, 0.95);
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.pattern-tooltip-label {
  margin: 0 0 5px 0;
  font-weight: bold;
  color: #333;
}

.pattern-tooltip-value {
  margin: 3px 0;
  font-size: 14px;
}

.pattern-tooltip-value.added {
  color: #008a47;
}

.pattern-tooltip-value.sale {
  color: #046ed1;
}

.pattern-insights {
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  margin-top: 20px;
}

.pattern-insights h4 {
  margin-top: 0;
  color: #333;
  font-size: 18px;
  margin-bottom: 15px;
}

.pattern-insights h5 {
  color: #555;
  font-size: 16px;
  margin-bottom: 10px;
}

.peak-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-bottom: 20px;
}

.peak-item {
  background-color: white;
  border-radius: 6px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.peak-label {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.peak-value {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.pattern-summary {
  line-height: 1.5;
  color: #555;
}

/* Responsive design */
@media (max-width: 768px) {
  .pattern-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .pattern-header-controls {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .day-select-wrapper, .chart-type-wrapper {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .day-select, .chart-type-select {
    width: 100%;
  }
  

  .peak-grid {
    grid-template-columns: 1fr;
  }
}

.pattern-header-controls {
display: flex;
flex-direction: column;
gap: 15px;
}

.pattern-control-row {
display: flex;
align-items: center;
gap: 15px;
flex-wrap: wrap;
}

/* Update existing media query for mobile responsiveness */
@media (max-width: 768px) {
.pattern-header-controls {
  width: 100%;
}

.pattern-control-row {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 10px;
}

.day-select-wrapper,
.chart-type-wrapper {
  width: 100%;
}

.day-select,
.chart-type-select{
  width: 100%;
}
}


.pattern-filter-controls {
background-color: #f5f7fa;
padding: 15px;
border-radius: 8px;
margin: 15px 0;
}

.pattern-filter-controls .filter-grid {
display: grid;
grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
gap: 15px;
margin-bottom: 15px;
}

.pattern-filter-controls .filter-group {
display: flex;
flex-direction: column;
}

.pattern-filter-controls .filter-group label {
font-size: 14px;
margin-bottom: 5px;
color: #555;
}

.pattern-filter-controls .filter-select {
padding: 8px 10px;
border: 1px solid #ddd;
border-radius: 4px;
font-size: 14px;
color: #333;
}

.pattern-filter-controls .active-filters {
display: flex;
flex-wrap: wrap;
gap: 8px;
margin-top: 10px;
padding-top: 10px;
border-top: 1px solid #eee;
}

.pattern-filter-controls .filter-tag {
background-color: #e1f5fe;
color: #0277bd;
padding: 5px 10px;
border-radius: 15px;
font-size: 12px;
font-weight: 500;
}

.pattern-filter-controls .reset-filters-btn {
padding: 8px 15px;
border-radius: 4px;
cursor: pointer;
font-size: 14px;
border: none;
transition: all 0.2s ease;
background-color: #e0e0e0;
color: #333;
}

.pattern-filter-controls .reset-filters-btn:hover {
background-color: #c0c0c0;
}

.pattern-filter-controls .reset-filters-btn:disabled {
opacity: 0.6;
cursor: not-allowed;
}

/* Media queries for responsive design */
@media (max-width: 768px) {
.pattern-filter-controls .filter-grid {
  grid-template-columns: 1fr;
}

.pattern-filter-controls .reset-filters-btn {
  width: 100%;
}
}