/* WeekSelection.css */
.week-selection {
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
  }
  
  .week-type-selector {
    min-width: 150px;
  }
  
  .week-type-select {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    background-color: #f9f9f9;
    cursor: pointer;
    width: 100%;
    transition: all 0.2s ease;
  }
  
  .week-type-select:hover {
    border-color: #bbb;
  }
  
  .week-type-select:focus {
    outline: none;
    border-color: #0093e1;
    box-shadow: 0 0 0 2px rgba(0, 147, 225, 0.1);
  }
  
  .custom-week-selector {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .custom-week-input {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .custom-week-input:hover {
    border-color: #bbb;
  }
  
  .custom-week-input:focus {
    outline: none;
    border-color: #0093e1;
    box-shadow: 0 0 0 2px rgba(0, 147, 225, 0.1);
  }
  
  .week-range-display {
    font-size: 13px;
    color: #666;
    font-style: italic;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .week-selection {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
    }
    
    .week-type-selector {
      width: 100%;
    }
    
    .custom-week-selector {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
    }
    
    .custom-week-input {
      width: 100%;
    }
  }